(function () {
  'use strict';

  class SchoolsCtrl {
    constructor(Media, Schools, $mdToast, $scope, Classrooms, Groups, User, EditSchools, EditClassrooms, EditGroups, EditStudents, EditTeachers, Modules, $element, $filter, $window, $rootScope, Utils, $mdDialog, ModuleSet) {
      let vm = this;
      vm.Media = Media;
      vm.Schools = Schools;
      vm.Groups = Groups;
      vm.Classrooms = Classrooms;
      vm.ModuleSet = ModuleSet;
      vm.$rootScope = $rootScope;
      vm.EditStudents = EditStudents;
      vm.$element = $element;
      vm.$mdDialog = $mdDialog;
      vm.Utils = Utils;
      vm.$filter = $filter;
      vm.$window = $window;
      vm.EditTeachers = EditTeachers;
      vm.Modules = Modules;
      vm.EditGroups = EditGroups;
      vm.EditSchools = EditSchools;
      vm.EditClassrooms = EditClassrooms;
      vm.User = User;
      vm.$mdToast = $mdToast;
      vm.isTeacher = Utils.roleCheck(['Teacher']);
      vm.currentUser = $rootScope.user;
      vm.ctrlName = 'SchoolsCtrl';
      vm.usernameRegex = /^[a-z0-9]+$/;
      vm.classroom = {
        teacher_id: vm.isTeacher ? vm.currentUser.id : null,
        school_id: vm.isTeacher ? vm.currentUser.school_id : null
      };
      vm.group = {
        keywords: []
      };
      $scope.$watch('schools.import', () => {
        if (vm.import && vm.import.file) {
          vm.importUsers();
        }
      });
      vm.init();
    }
    importUsers() {
      let vm = this;
      vm.User.importUsers(vm.import.file)
        .then(()=> {
          vm.message('Users Imported Succesfully.');
          vm.init();
        });
    }
    init() {
      let vm = this;
      vm.getSchools();
      vm.getSchoolsAll();
      vm.getGroups();
      vm.getClassrooms();
      vm.ModuleSet.moduleSetIndex()
        .then((data)=>{
          vm.modulesIndex = data;
          vm.$element.find('input').on('keydown', function (ev) {
            ev.stopPropagation();
          });
        });
      vm.moduleSetIndex();
      if (vm.Utils.roleCheck(['admin', 'superadmin'])) {
        vm.getTeachers();
        vm.getAdmins();
      }
    }
    getClassrooms(search = {}) {
      const vm = this;
      vm.loadingClassroom = true;
      vm.Classrooms.classroomsIndex(search)
        .then((data)=> {
          vm.classroomsIndex = data;
        })
        .finally(() => {
          vm.loadingClassroom = false;
        });
    }
    getSchools(search = {}) {
      const vm = this;
      vm.loadingSchools = true;
      vm.Schools.schoolsIndex(search)
        .then((data)=> {
          vm.schoolsIndex = data;
        })
        .finally(() => {
          vm.loadingSchools = false;
        });
    }
    getSchoolsAll(search = {}) {
      const vm = this;
      search.per_page = 9999;
      vm.Schools.schoolsIndex(search)
        .then((data)=> {
          vm.schoolsIndexAll = data;
        });
    }
    getGroups(search = {}) {
      const vm = this;
      vm.loadingGroups = true;
      vm.Groups.groupsIndex(search)
        .then((data)=> {
          vm.groupsIndex = data;
        })
        .finally(() => {
          vm.loadingGroups = false;
        });
    }
    getAdmins(search = {}) {
      const vm = this;
      vm.loadingAdmin = true;
      vm.User.getAdmins(search)
        .then((data)=> {
          vm.adminsIndex = data;
        })
        .finally(() => {
          vm.loadingAdmin = false;
        });
    }
    getTeachers(search = {}) {
      const vm = this;
      vm.loadingTeacher = true;
      vm.User.getTeachers(search)
        .then((data)=> {
          vm.teachersIndex = data;
        })
        .finally(() => {
          vm.loadingTeacher = false;
        });
    }
    previousAdmin() {
      const vm = this;
      vm.getAdmins({page: vm.adminsIndex.meta.page - 1});
    }
    nextAdmin() {
      const vm = this;
      vm.getAdmins({page: vm.adminsIndex.meta.page + 1});
    }
    previousClassroom() {
      const vm = this;
      vm.getClassrooms({page: vm.classroomsIndex.meta.page - 1});
    }
    nextClassroom() {
      const vm = this;
      vm.getClassrooms({page: vm.classroomsIndex.meta.page + 1});
    }
    previousTeacher() {
      const vm = this;
      vm.getTeachers({page: vm.teachersIndex.meta.page - 1});
    }
    nextTeacher() {
      const vm = this;
      vm.getTeachers({page: vm.teachersIndex.meta.page + 1});
    }
    previousSchool() {
      const vm = this;
      vm.getSchools({page: vm.schoolsIndex.meta.page - 1});
    }
    nextSchool() {
      const vm = this;
      vm.getSchools({page: vm.schoolsIndex.meta.page + 1});
    }
    previousGroup() {
      const vm = this;
      vm.getGroups({page: vm.groupsIndex.meta.page - 1});
    }
    nextGroup() {
      const vm = this;
      vm.getGroups({page: vm.groupsIndex.meta.page + 1});
    }
    moduleSetIndex() {
      const vm = this;
      vm.ModuleSet.moduleSetIndex()
        .then((data) => {
          vm.ModuleSetsPayload = data;
        });
    }
    createAdmin(form) {
      const vm = this;
      if (form.$valid) {
        vm.User.createAdmin(vm.admin)
        .then(() => {
          vm.message('Administrator Created Succesfully.');
          vm.resetFields(form);
          vm.getAdmins();
        }).catch((data) => {
          vm.message(data.data.message[0]);
        });
      }
    }
    initClassroom(param, teacher, bol) {
      let vm = this;
      vm.Classrooms.classroomsIndex(param)
        .then((data)=> {
          if (teacher) {
            teacher.classroomsIndexFilter = data;
            if (bol) {
              teacher.classroom_ids = [];
            }
          } else {
            vm.classroomsIndexFilter = data;
          }
        });
    }
    schoolsCreate(form) {
      let vm = this;
      vm.Schools.schoolsCreate(vm.school)
        .then(()=> {
          vm.message('School Created Succesfully.');
          vm.resetFields(form);
          vm.init();
        })
        .catch(()=> {
          vm.message('Something went wrong, please try again later.');
        });
    }
    schoolSubmit(form) {
      let vm = this;
      if (form.$valid) {
        vm.schoolsCreate(form);
      }
    }
    uploadMedia(form) {
      const vm = this;
      vm.Media.mediaCreate(vm.media.file)
        .then((data)=>{
          vm.school.logo = data.file.url;
          vm.schoolsCreate(form);
        })
        .catch(()=> {
          vm.message('Something went wrong, please try again later.');
        });
    }
    message(msg) {
      let vm = this;
      vm.$mdToast.show(
        vm.$mdToast.simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(3000)
      );
    }
    classRoomSubmit(form) {
      let vm = this;
      if (form.$valid) {
        angular.forEach(vm.classroom.module_sets, function (v, k, o) {
          o[k] = {id: v};
        });
        vm.Classrooms.classroomsCreate(vm.classroom)
          .then(()=> {
            vm.message('Classroom Created Succesfully.');
            vm.resetFields(form);
            vm.init();
          })
          .catch(()=> {
            vm.message('Something went wrong, please try again later.');
          });
      }
    }
    setClassroom(classroom, teacher, msg, form) {
      let vm = this;
      vm.Classrooms.classroomCreate({id: classroom.id, teacher_id: teacher.id})
        .then(()=> {
          vm.message(msg ? msg : 'Classroom assigned Succesfully.');
          vm.resetFields(form);
          vm.init();
        })
        .catch(()=> {
          vm.message('Something went wrong, please try again later.');
        });
    }
    createGroups(form) {
      const vm = this;
      if (form.$valid) {
        vm.Groups.groupsCreate(vm.group)
          .then(()=>{
            vm.message('Group Created Succesfully.');
            vm.resetFields(form);
            vm.init();
          })
          .catch((response) => {
            const {data: {
              message
            }} = response;
            vm.message(message);
          });
      }
    }
    deleteGroups(group, ev) {
      let vm = this,
          confirm = vm.$mdDialog.confirm()
            .title('Confirm')
            .textContent('Are you sure you want to delete this group?')
            .ariaLabel('Confirm')
            .targetEvent(ev)
            .ok('Delete')
            .cancel('Cancel');

      vm.$mdDialog.show(confirm).then(function () {
        vm.Groups.groupDelete({id: group.id})
        .then(()=>{
          vm.message('Group Deleted Succesfully.');
          vm.init();
        })
        .catch(()=> {
          vm.message('Something went wrong, please try again later.');
        });
      });
    }
    openMenu($mdMenu, ev) {
      $mdMenu.open(ev);
    }
    setGroup(group, student) {
      const vm = this,
          obj = {
            id: group.id,
            student_id: student.id
          };
      vm.Groups.assignStudent(obj)
        .then(()=>{
          vm.message('Student Sent to group Succesfully');
          vm.init();
        })
        .catch(()=> {
          vm.message('Something went wrong, please try again later.');
        });
    }
    inviteStudent(form) {
      const vm = this;
      vm.user.role = 'Learner';
      if (form.$valid) {
        vm.createUser(vm.user, form);
      }
    }
    editSchools(e, school) {
      let vm = this;
      vm.EditSchools.show(e, school, ()=> {
        vm.message('School updated');
        vm.init();
      });
    }
    editTeachers(e, teacher) {
      let vm = this;
      vm.EditTeachers.show(e, teacher, ()=> {
        let msg = teacher.role === 'Teacher' ? 'Teacher Updated.' : 'Administrator Updated.';
        vm.message(msg);
        vm.init();
      });
    }
    deleteUser(user, ev) {
      let vm = this,
          confirm = vm.$mdDialog.confirm()
            .title('Confirm')
            .textContent('Are you sure you want to delete this?')
            .ariaLabel('Confirm')
            .targetEvent(ev)
            .ok('Delete')
            .cancel('Cancel');

      if (user.id) {
        vm.$mdDialog.show(confirm).then(function () {
          vm.User.userDelete({id: user.id})
            .then(()=>{
              vm.message('User deleted');
              vm.init();
            })
            .catch(()=> {
              vm.message('Something went wrong, please try again later.');
            });
        });
      }
    }
    deleteSchool(school) {
      let vm = this;
      vm.Schools.schoolDelete({id: school.id})
        .then(()=> {
          vm.message('School deleted');
          vm.init();
        })
        .catch(()=> {
          vm.message('Something went wrong, please try again later.');
        });
    }
    deleteClassroom(classroom, ev) {
      let vm = this,
          confirm = vm.$mdDialog.confirm()
            .title('Confirm')
            .textContent('Are you sure you want to delete this?')
            .ariaLabel('Confirm')
            .targetEvent(ev)
            .ok('Delete')
            .cancel('Cancel');

      vm.$mdDialog.show(confirm).then(function () {
        vm.Classrooms.classroomDelete({id: classroom.id})
          .then(()=> {
            vm.message('Classroom deleted');
            vm.init();
          })
          .catch(()=> {
            vm.message('Something went wrong, please try again later.');
          });
      });
    }
    exportClassroom(classroom) {
      let vm = this,
          toast = vm.$mdToast;

      toast.show(vm.$mdToast.simple().textContent('Please wait').position('bottom right'));
      vm.Classrooms.export({id: classroom.id})
        .then((response)=> {
          vm.$window.open(response.media.url, '_blank');
          toast.hide();
        })
        .catch(()=> {
          vm.message('Something went wrong, please try again later.');
        });
    }
    editClassrooms(e, classroom) {
      let vm = this;
      vm.EditClassrooms.show(e, classroom, vm.schoolsIndex, function () {
        vm.message('Classroom updated');
        vm.init();
      });
    }
    editGroups(e, group) {
      let vm = this;
      vm.EditGroups.show(e, group, vm.classroomsIndex, function () {
        vm.message('Group updated');
        vm.init();
      });
    }
    editStudents(e, student) {
      let vm = this;
      vm.EditStudents.show(e, student, function () {
        vm.message('Student updated');
        vm.init();
      });
    }
    resetFields(form) {
      let vm = this;
      if (form) {
        form.$setPristine();
        form.$setUntouched();
      }
      delete vm.administrator;
      delete vm.school;
      delete vm.teacher;
      delete vm.user;
      vm.classroom = {};
      vm.group = {
        student: {}
      };
    }
    createUser(user, form) {
      let vm = this;
      vm.User.signup(user, true)
        .then((data)=>{
          if (user.classroom) {
            vm.setClassroom(user.classroom, data.user, 'Teacher created.', form);
          } else {
            vm.message((data.user.role === 'admin' ? 'Administrator ' : 'User ') + 'Created.');
            vm.resetFields(form);
            vm.init();
          }
        })
        .catch((data)=>{
          if (data.status === 403) {
            vm.error = data;
          }
        });
    }
    inviteTeacher(form) {
      const vm = this;
      if (form.$valid) {
        vm.User.createTeacher(vm.teacher)
          .then(() => {
            vm.message('Teacher Created Succesfully.');
            vm.resetFields(form);
            vm.init();
          });
      }
    }
    updateTeacher(teacher) {
      let vm = this;
      vm.User.userEdit(teacher)
        .then(()=> {
          vm.message('Teacher Updated.');
        });
    }
    clearSearchTerm(group) {
      let vm = this;
      vm.Groups.groupEdit(group)
        .then(()=> {
          vm.message('Group Updated.');
          // vm.init();
        })
        .catch(()=> {
          vm.message('Something went wrong, please try again later.');
        });
    }
  }

  /**
   * @ngdoc object
   * @name dashboard.schools.controller:SchoolsCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard.schools')
    .controller('SchoolsCtrl', SchoolsCtrl);
}());
